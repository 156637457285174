


























































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import EadInfoModal from "@monorepo/inventory/src/views/EadView/components/EadInfoModal.vue";
import ToggleTableCellsContainer from "@monorepo/inventory/src/views/EadView/components/ToggleTableCellsContainer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/eadView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useGetTableLibs from "./composables/useGetTableLibs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { mapActions, mapGetters, Module, mapMutations } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/EadView/constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { eadView } from "@monorepo/inventory/src/store/modules/eadView";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import EadStatus from "./components/EadStatus.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/EadView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/eadView/filtersElements";
import {
  currentFilters as setCurrentFilters,
  currentFiltersTop as setCurrentFiltersTop,
} from "@monorepo/inventory/src/views/EadView/utils/defaultCurrentFilters";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";
import { IDocSection } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { convertApiTreeItemToUi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import FiltersTopNew from "@monorepo/uikit/src/components/common/FiltersTopNew.vue";
import TableNew from "@monorepo/inventory/src/views/EadView/components/newView/TableNew.vue";
import PreviewModalNew from "@monorepo/inventory/src/components/newView/PreviewModalNew.vue";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const SECTION_NAME = "eadView";

export default defineComponent({
  name: "EadView",
  components: {
    TableSettings,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    ExportButton,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    EadInfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    ToggleDocumentView,
    Footer,
    ExportFooterButton,
    EadStatus,
    FiltersTopNew,
    TableNew,
    PreviewModalNew,
    CasesTree: () =>
      import(
        /* webpackChunkName: "casesTree" */
        "@monorepo/inventory/src/views/EadView/components/CasesTree.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      tableHeaders,
      viewUniqKey,
      showEadModal: false,
      ViewMode,
      pluralFormTitles: ["ЭАД", "ЭАДа", "ЭАДов"],
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      setCurrentFiltersTop,
      isShowPdf: false,
      fileId: null as string | null,
      files: [] as any[],
      autocompleteFiltersKeys: [fields.FILTER_FUND, fields.FILTER_OIK, fields.FILTER_KEYWORD],
      chipboardItems: [
        {
          title: "Присутствует",
          value: true,
        },
        {
          title: "Отсутствует",
          value: false,
        },
      ],
      additionalSort: {
        documentTypeIndex: ["documentTypeSectionIndex", "documentTypeIndex"],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "data",
      "openedId",
      "isOpenFilters",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "sectionsList",
      "elasticValue",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    resultFiltersElement(): any {
      return !this.elasticValue ? this.filtersElements.slice(1) : this.filtersElements;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.EAD_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
    statusOptions(): { title: string; value: string }[] {
      return convertToSelectItems(["Получен", "Не получен", "Удалён"]);
    },
    sectionsTreeItems() {
      return ((this.sectionsList as IDocSection[]) || [])
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((item) => convertApiTreeItemToUi(item, false, true));
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        chipboardItems: this.chipboardItems,
        statusOptions: this.statusOptions,
        sectionsTreeItems: this.sectionsTreeItems,
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "getExportData",
      "addOpenedId",
      "setIsOpenFilters",
      "getTreeData",
      "clearFilters",
      "setAutorefresh",
    ]),
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    changePreviewFiles({ files, fileId }: { files: any[]; fileId: string }) {
      this.files = files;
      this.fileId = fileId;
    },
    toggleDocumentView(view: ViewMode) {
      this.setAutorefresh(view === ViewMode.TABLE);
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch("eadView/addOpenedId", event.id);
          this.toggleModal(true);
          break;
        case fields.OIK_SHORT_NAME: {
          this.moveByAuthorities(header, "/dictionaries/oik", {
            name: event[fields.OIK_SHORT_NAME] as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.DOC_TYPE_ID: {
          this.moveByAuthorities(header, "/dictionaries/doc-type", {
            id: event[fields.DOC_TYPE_ID] as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.FUND_NUMBER: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            number: event[fields.FUND_NUMBER] as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.EAD_CASE_INDEX: {
          this.moveByAuthorities(header, "/inventory/case", {
            index: event[fields.EAD_CASE_INDEX] as string,
            isOpenModal: "1",
          });
          break;
        }
        case fields.EAD_CASE_TITLE: {
          this.moveByAuthorities(header, "/inventory/case", {
            header: event[fields.EAD_CASE_TITLE] as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: Record<string, unknown>[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, eadView as Module<unknown, unknown>);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartFilters(root, SECTION_NAME, ["id", "title"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
      moveByAuthorities,
    };
  },
});

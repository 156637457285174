import moment from "moment";

export const convertApiItemToUi = (element: any): any => {
  const addressObj = (element.caseDto?.addressList || []).reduce((result: Record<string, string>, current: any) => {
    result[current.type?.toLowerCase() || ""] = current.value;
    return result;
  }, {});
  return {
    ...element,
    highlights: element.highlightFields ? element.highlightFields : [],
    score: element.score,
    registrationDate: element.registrationDate ? moment(element.registrationDate).format("YYYY-MM-DD") : "",
    createDate: element.createDate && element.status === "Получен" ? moment(element.createDate).format("YYYY-MM-DD HH:mm") : "",
    arrivalDate: element.arrivalDate ? moment(element.arrivalDate).format("YYYY-MM-DD HH:mm") : "",
    oikId: element.oik?.name || "",
    fundNumber: element.fund?.number || "",
    fundName: element.fund?.name || "",
    caseHeader: element.caseDto?.header || "",
    caseNumber: element.caseDto?.number || "",
    akId: element.archiveContainer?.id || 0,
    digitalSignatures:
      (element.digitalSignatures || []).map((item: any) => ({
        ...item,
        expiredAt: item.expiredAt ? moment(item.expiredAt).format("YYYY-MM-DD HH:mm") : "",
        signTime: item.signTime ? moment(item.signTime).format("YYYY-MM-DD HH:mm") : "",
        timeStamp: item.timeStamp ? moment(item.timeStamp).format("YYYY-MM-DD HH:mm") : "",
      })) || [],
    caseIndex: element.caseDto?.index?.toString() || "",
    inventoryNumber: element.caseDto?.inventoryEad?.number || element.caseDto?.inventoryPaper?.number || "",
    inventoryYear: element.caseDto?.inventoryEad?.year || element.caseDto?.inventoryPaper?.year || "",
    isDSP: element.isDsp ? "Да" : "Нет",
    pdfLink: element.mainFileGuid,
    comment: element.comment || "",
    caseArchiveCipher: element.caseDto?.archiveCipher || "",
    storageKind: element.storageKind?.title || "",
    commentUpdateDate: element.commentUpdateDate ? moment(element.commentUpdateDate).format("YYYY-MM-DD HH:mm") : "",
    commentUpdateUser: element.commentUpdateUser || "",
    documentTypeSection: element.documentTypeSectionIndex ?? "",
    documentTypeDoc: element.documentTypeIndex ?? "",
    storageExpirationDate: element.storageExpirationDate ? moment(element.storageExpirationDate).format("YYYY-MM-DD") : "",
    archiveName: element.archive?.name || "",
    archiveAddress: element.archive?.address || "",
    mrdPowerAttorneyNumber: element.mrdDto?.powerAttorneyNumber || "",
    mrdCreationDate: element.mrdDto?.creationDate ? moment(element.mrdDto.creationDate).format("YYYY-MM-DD HH:mm") : "",
    mrdExpiredAt: element.mrdDto?.expiredAt ? moment(element.mrdDto.expiredAt).format("YYYY-MM-DD HH:mm") : "",
    mrdTrusteeCompanyName: element.mrdDto?.trusteeCompanyName || "",
    mrdTrusteeInn: element.mrdDto?.trusteeInn || "",
    mrdTrusteeOgrn: element.mrdDto?.trusteeOgrn || "",
    mrdTrusteeFio: element.mrdDto?.trusteeFio || "",
    mrdRepresentativeFio: element.mrdDto?.representativeFio || "",
    mrdPermissions: element.mrdDto?.permissionText || "",
    authenticity: element.authenticity?.title || "",
    storageStatus: element.caseDto?.status?.title || "",
    statusComment: element.caseDto?.statusComment || "",
    house: addressObj.house || "",
    floor: addressObj.floor || "",
    archiveStorage: addressObj.archive_storage || "",
    room: addressObj.room || "",
    shelving: addressObj.shelving || "",
    cabinet: addressObj.cabinet || "",
    shelf: addressObj.shelf || "",
    additionalInfoOfPlacementAddress: element.caseDto?.additionalInfoOfPlacementAddress || "",
    dateTransferToStorage: element.caseDto?.dateTransferToStorage || "",
  };
};

export const getResultSectionName = (item: any) => {
  const orderIndexPath = item?.orderIndexPath ? `${item.orderIndexPath} ` : "";
  let result = `${orderIndexPath}${item?.name || ""}`;
  if (item?.parent?.name) {
    result = `${getResultSectionName(item?.parent)} ${result || ""}`;
  }
  return result || "";
};
